.navbar {
    margin: 0px;
    width: 100%;
    height: 60px;
    transition: .3s;  
}

.sticky {
  position: fixed;
  top: 0;
  z-index: 100;
  background: #ffffff;
}

.links {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: right;
    color: black;
  }
  
  .navbar a {
    color: black;
    text-decoration: none;
    margin: 20px;
    font-size: 22px;
    display: block;
  }
  
  .toggleButton {
    width: 100%;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: sticky;
  }
  
  .toggleButton svg {
    font-size: 50px;
  }
  .toggleButton button {
    margin-right: 20px;
    background-color: transparent;
    border: none;
    color: black;
    cursor: pointer;
  }
  
  #open {
    height: 100vh;
  }
  
  #open .links {
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 100px;
  }
  
  #open a {
    width: 100%;
    text-align: center;
  }
  
  @media only screen and (max-width: 900px) {
    .navbar a {
      width: 70px;
    }
  }
  
  @media only screen and (max-width: 600px) {
    .toggleButton {
      display: flex;
    }
    #close a {
      display: none;
    }
  }
  
  @media only screen and (min-width: 600px) {
    .toggleButton {
      display: none;
    }
  
    .hiddenLinks {
      display: none;
    }
  
    #open {
      height: 100px;
    }
  
    #open .links {
      flex-direction: row;
    }
  }