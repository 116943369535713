.footer {
    width: 100%;
    height: 130px;
    background: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 10px;
    margin-top: 280px;
  }
  
  .socialMedia svg {
    color: white;
    margin: 15px;
    font-size: 40px;
    cursor: pointer;
  }
  
  .footer p {
    color: white;
  }
  
  @media only screen and (max-width: 600px) {
    .footer svg {
      font-size: 50px;
    }
  }