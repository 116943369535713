
html {
    background: url(../assets/clouds_bg.jpg) no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    
}

.home {
    margin-left: 20%;
    text-align: left;
    display: revert;
    
}

.title {
    font-size: 60px;
    font-weight: 500;
}

.welcome {
    margin-top: -30px;
}

.deco {
    margin-top: 30px;
    font-size: 50px;
}