.container, .about, .cassandra {
    margin-left: 250px;
    margin-right: 250px;
}

.me {
    width: 100%;
    position: relative;
    margin-left: 100px;
    top: -60px;
    max-width: 300px;
    height: auto;
}

.about, h2 {
    font-family: 'Caudex', serif;
}

.about {
    font-size: 76px;
    position: relative;
    top: 150px;
    text-align: left;
    font-weight: normal;
}

.cassandra {
    position: relative;
    top: 120px;
    font-size: 1.2rem;
}

h2 {
    font-size: 40px;
    text-align: center;

}

p, ul, h3 {
    font-family: Arial, Helvetica, sans-serif;
    text-align: left;
}

.container {
    display: flex;
}

.info {
    width: 50%;
    position: relative;
    top: 130px;
    line-height: 1.6;
    font-size: 1.2rem;
}

.skills-list {
    display: flex;
    justify-content: space-evenly;
    font-size: 1.2rem;
    line-height: 1.4;
}


@media only screen and (max-width: 1200px) {
    .container, .about, .cassandra {
        margin-left: 100px;
        margin-right: 100px;
    } 
}

@media only screen and (max-width: 900px) {
    .container, .about, .cassandra {
        margin-left: 50px;
        margin-right: 50px;
    } 
    h1 {
        font-size: 60px;
    }
    h2 {
        font-size: 28px;
    }
    .info {
        font-size: 0.98rem;
    }
}

@media only screen and (max-width: 700px) {
    .container {
        display: block;
    }
    .me, .info, .about, .cassandra {
        position: static;
    }

    .info {
        width: 100%;
    }

    .me {
        margin: 0;
    }
}
